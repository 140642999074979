<template>
    <!-- form modal -->

    <div
        v-if="showEmailToModal"
        tabindex="0"
        @keydown.esc="closeModal"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showEmailToModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-30-l w-50 pv2 center box-border" style="background: white" ref="modalRef">
            <div class="flex justify-between pa3 box-border-bottom">
                <div class="f3 b">Send Report</div>
                <div>
                    <router-link to="#">
                        <img src="../../../assets/images/Close-icon.svg" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>

            <form class="entity-form" ref="formRef" @submit.prevent="onSubmit">
                <div class="pv1 ph3">
                    <div class="pt1">
                        <div class="pv2 font-w1"><label for="name">Email</label></div>
                        <input type="email" placeholder="Enter email address" v-model="state.email" required />
                    </div>

                    <div class="flex items-center justify-center w-100">
                        <button class="mt4 w-100" type="submit">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'PnlEmailTo',
    props: {
        closeModal: Function
    },

    setup(props, { emit }) {
        const modalRef = ref(null)
        const store = useStore()
        const showEmailToModal = computed(() => store.state?.Reporting?.showEmailToModal)

        const initialState = {
            email: ''
        }
        const state = reactive(initialState)

        const onSubmit = () => {
            emit('onEmailSubmitted', state.email)
            props.closeModal()
        }

        const handleOnClickOutsideModal = e => {
            if (modalRef?.value && !modalRef?.value.contains(e.target)) {
                props.closeModal()
            }
        }

        return {
            state,
            onSubmit,
            showEmailToModal,
            modalRef,
            handleOnClickOutsideModal
        }
    }
}
</script>

<style>
.entity-form input[type='radio'] {
    background: unset;
    border: none;
    box-shadow: none;
    padding: unset;
    width: unset;
}
</style>